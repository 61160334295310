export {QUOTE}

const QUOTE = {
  init() {
    const form = document.querySelector('#pricing-form')
    if (!form) return

    const amountFields = [...form.querySelectorAll('.amount-field')];
    const quantityDisplays = [...form.querySelectorAll('#quantity')]
    const checkBoxes = [...form.querySelectorAll('.count-toward-pricing')]
    const totalRows = [...form.querySelectorAll('#total_amounts')]
    const totalField = form.querySelector('#total')
    const totalDisplay = form.querySelector('#total-display')

    var update_total_rows = function()
    {
      for(var i = 0; i < totalRows.length; i++)
      {
        if(checkBoxes[i].checked)
        {
          totalRows[i].nextSibling.data = parseFloat((amountFields[i].value * quantityDisplays[i].innerText) * (percentageField.value / 100)).toFixed(2);
        }
        else
        {
          totalRows[i].nextSibling.data = 0;
        }
      }
      calculateTotal();
    }

    var calculateTotal = function()
    {
      var total = 0;
      for(var i = 0; i < totalRows.length; i++)
      {
        total += parseFloat(totalRows[i].nextSibling.data);
      }
      totalField.value = total.toFixed(2);
      totalDisplay.innerHTML = total.toFixed(2);
    }

    amountFields.forEach(field => field.addEventListener('input', update_total_rows))
    checkBoxes.forEach(box => box.addEventListener('input', update_total_rows))

    const percentageField = document.querySelector('input#percentage')

    percentageField.addEventListener('change', update_total_rows);
    percentageField.addEventListener('keydown', function(){ setTimeout(update_total_rows, 5) } );
    for(var i = 0; i < checkBoxes.length; i++)
    {
      checkBoxes[i].addEventListener('change', update_total_rows);
    }

    update_total_rows();
  }
}
