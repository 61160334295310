import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['left', 'right']

  switch() {
    if (this.leftTarget.classList.contains('hide')) {
      this.rightTarget.classList.add('hide')
      this.leftTarget.classList.remove('hide')
    } else {
      this.leftTarget.classList.add('hide')
      this.rightTarget.classList.remove('hide')
    }
  }
}
