import {DataTable} from "simple-datatables";
import {initClickableRows} from "./utils";
import {JSTable} from "jstable";
import {TASK} from "./tasks";

export {DASHBOARD};

const DASHBOARD = {};
DASHBOARD.eventsDataTable = null;
DASHBOARD.mobileEventsDataTable = null;
DASHBOARD.activityDataTable = null;

// This prevent duplicate load of datatables function when we forward/backward
DASHBOARD.beforeCacheClear = function () {
  if (DASHBOARD.mobileDataTable != null) {
    DASHBOARD.mobileDataTable.destroy();
    DASHBOARD.mobileDataTable = null
  }
  if (DASHBOARD.mobileEventsDataTable != null) {
    DASHBOARD.mobileEventsDataTable.destroy();
    DASHBOARD.mobileEventsDataTable = null
  }
  if (DASHBOARD.activityDataTable != null) {
    DASHBOARD.activityDataTable.destroy();
    DASHBOARD.activityDataTable = null
  }

}

DASHBOARD.init = function() {
  let eventsTable = document.querySelector('.events_datatable');
  if (eventsTable != null) {
    let originalTable = eventsTable.cloneNode(true);
    let nonSortableCells = [...eventsTable.querySelectorAll('th')].map(function(element, index){
      if (element.dataset.sortable == 'false') return index
    }).filter(Number);
    let sortedCol = eventsTable.querySelector('th[data-sorted]');
    let sortedColIdx = [...eventsTable.querySelectorAll('thead th')].findIndex(function(el){ return el == sortedCol});
    let sortedColDirection = 'asc';
    let deferLoading = (eventsTable.dataset.count == undefined) ? null : eventsTable.dataset.count

    if (sortedColIdx == -1) {
      sortedColIdx = 0;
    } else {
      sortedColDirection = sortedCol.dataset.sorted;
    }

    var parse_string_params = function(params) {
      var object = {};
      for (var i = 0; i < params.length-1; i++)
      {
        object[params[i]] = params[i+1];
      }
      return object;
    }

    var padTo2Digits = function(num) {
      return num.toString().padStart(2, '0');
    }

    var formatDate = function(date) {
      return [
        padTo2Digits(date.getDate()),
        padTo2Digits(date.getMonth() + 1),
        date.getFullYear(),
      ].join('.');
    }

    var dateRenderer = function (cell, idx) {
      let data = cell.innerHTML;
      if(data == '')
      {
        return '';
      }
      return formatDate(new Date(parseInt(data)*1000));
    }


    var columns = [{
      select: nonSortableCells,
      sortable: false
    }]

    var theads = eventsTable.querySelectorAll('th');
    for(var i = 0; i < theads.length; i++)
    {
      var thead = theads[i];
      var column = {};
      column.select = [i];
      column.sortable = true;
      if(sortedColIdx == i)
      {
        column.sort = sortedColDirection;
      }
      column.render = thead.dataset.rendermethod ? eval(thead.dataset.rendermethod) : function(cell, idx){ return cell.innerHTML };

      columns.push(column);
    }

    DASHBOARD.mobileDataTable = new JSTable(eventsTable, {
      labels: {
        perPage: 'Zobrazit záznamů: {select}',
        noRows: 'Žadná data',
        placeholder: 'Hledat...',
        info: 'Zobrazuji {start} až {end} z {rows} záznamů',
        loading: 'Načítám data'
      },
      perPage: 20,
      perPageSelect: [5, 10, 20, 40, 60, 80, 100],
      columns: columns,
      searchable: true,
      serverSide: eventsTable.dataset.url != undefined,
      classes: { search: 'dataTable-search', dropdown: 'dataTable-dropdown', input: 'dataTable-input' },
      ajax: eventsTable.dataset.url,
      ajaxParams: eventsTable.dataset.params == undefined ? {} : parse_string_params(eventsTable.dataset.params.split(",")),
      deferLoading: deferLoading
    });
    if(DASHBOARD.mobileDataTable.config.classes.search == 'hidden')
    {
      DASHBOARD.mobileDataTable.search(' ');
    }
    // No idea why wrapper is sometimes undefined
    if (DASHBOARD.mobileDataTable.wrapper) {
      DASHBOARD.mobileDataTable.originalTable = originalTable;
      M.FormSelect.init(DASHBOARD.mobileDataTable.wrapper.querySelector('select'));
    }
    var tooltip_init = function () {
      let tooltipped = DASHBOARD.mobileDataTable.wrapper.querySelectorAll('.tooltipped');
      M.Tooltip.init(tooltipped);
    }
    tooltip_init();

    var tooltip_cleanup = function () {
      let tooltipped = DASHBOARD.mobileDataTable.wrapper.querySelectorAll('.tooltipped');
      tooltipped.forEach(tooltip => {
        const instance = M.Tooltip.getInstance(tooltip);
        if (instance != null) instance.destroy();
      });
    }

    DASHBOARD.mobileDataTable.on('update', tooltip_init);
    DASHBOARD.mobileDataTable.on('fetchData', tooltip_cleanup);
    DASHBOARD.mobileDataTable.on('paginate', function() {
      let url = window.location.pathname + window.location.search
      Turbolinks.controller.replaceHistoryWithLocationAndRestorationIdentifier(location, Turbolinks.uuid())
    });
    DASHBOARD.mobileDataTable.on('init', () => {
      initClickableRows(DASHBOARD.mobileDataTable.wrapper);
    });
  }


  let activityTable = document.querySelector('.activity_datatable');
  if (activityTable != null) {
    let originalTable = activityTable.cloneNode(true);
    let nonSortableCells = [...activityTable.querySelectorAll('th')].map(function(element, index){
      if (element.dataset.sortable == 'false') return index
    }).filter(Number);
    let sortedCol = activityTable.querySelector('th[data-sorted]');
    let sortedColIdx = [...activityTable.querySelectorAll('thead th')].findIndex(function(el){ return el == sortedCol});
    let sortedColDirection = 'asc';
    let deferLoading = (activityTable.dataset.count == undefined) ? null : activityTable.dataset.count

    if (sortedColIdx == -1) {
      sortedColIdx = 0;
    } else {
      sortedColDirection = sortedCol.dataset.sorted;
    }

    var parse_string_params = function(params) {
      var object = {};
      for (var i = 0; i < params.length-1; i++)
      {
        object[params[i]] = params[i+1];
      }
      return object;
    }

    var padTo2Digits = function(num) {
      return num.toString().padStart(2, '0');
    }

    var formatDate = function(date) {
      return [
        padTo2Digits(date.getDate()),
        padTo2Digits(date.getMonth() + 1),
        date.getFullYear(),
      ].join('.');
    }

    var dateRenderer = function (cell, idx) {
      let data = cell.innerHTML;
      if(data == '')
      {
        return '';
      }
      return formatDate(new Date(parseInt(data)*1000));
    }


    var columns = [{
      select: nonSortableCells,
      sortable: false
    }]

    var theads = activityTable.querySelectorAll('th');
    for(var i = 0; i < theads.length; i++)
    {
      var thead = theads[i];
      var column = {};
      column.select = [i];
      column.sortable = true;
      if(sortedColIdx == i)
      {
        column.sort = sortedColDirection;
      }
      column.render = thead.dataset.rendermethod ? eval(thead.dataset.rendermethod) : function(cell, idx){ return cell.innerHTML };

      columns.push(column);
    }

    DASHBOARD.activityDataTable = new JSTable(activityTable, {
      labels: {
        perPage: 'Zobrazit záznamů: {select}',
        noRows: 'Žadná data',
        placeholder: 'Hledat...',
        info: 'Zobrazuji {start} až {end} z {rows} záznamů',
        loading: 'Načítám data'
      },
      perPage: 20,
      perPageSelect: [5, 10, 20, 40, 60, 80, 100],
      columns: columns,
      searchable: true,
      serverSide: activityTable.dataset.url != undefined,
      classes: { search: 'dataTable-search', dropdown: 'dataTable-dropdown', input: 'dataTable-input' },
      ajax: activityTable.dataset.url,
      ajaxParams: activityTable.dataset.params == undefined ? {} : parse_string_params(activityTable.dataset.params.split(",")),
      deferLoading: deferLoading
    });
    if(DASHBOARD.activityDataTable.config.classes.search == 'hidden')
    {
      DASHBOARD.activityDataTable.search(' ');
    }
    // No idea why wrapper is sometimes undefined
    if (DASHBOARD.activityDataTable.wrapper) {
      DASHBOARD.activityDataTable.originalTable = originalTable;
      M.FormSelect.init(DASHBOARD.activityDataTable.wrapper.querySelector('select'));
    }
    var tooltip_init = function () {
      let tooltipped = DASHBOARD.activityDataTable.wrapper.querySelectorAll('.tooltipped');
      M.Tooltip.init(tooltipped);
    }
    tooltip_init();

    var tooltip_cleanup = function () {
      let tooltipped = DASHBOARD.activityDataTable.wrapper.querySelectorAll('.tooltipped');
      tooltipped.forEach(tooltip => {
        const instance = M.Tooltip.getInstance(tooltip);
        if (instance != null) instance.destroy();
      });
    }

    DASHBOARD.activityDataTable.on('update', tooltip_init);
    DASHBOARD.activityDataTable.on('fetchData', tooltip_cleanup);
    DASHBOARD.activityDataTable.on('paginate', function() {
      let url = window.location.pathname + window.location.search
      Turbolinks.controller.replaceHistoryWithLocationAndRestorationIdentifier(location, Turbolinks.uuid())
    });
    DASHBOARD.activityDataTable.on('init', () => {
      initClickableRows(DASHBOARD.activityDataTable.wrapper);
    });
  }
}
