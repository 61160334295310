const Turbolinks = require("turbolinks");

export function initClickableRows(root = document){
  let clickables = root.querySelectorAll('.clickable-row');
  clickables.forEach((clickable) => {
    clickable.addEventListener('click', (event:any) => {
      let tr = event.target.closest('tr, li');
      Turbolinks.visit(tr.attributes['data-href'].value);
    })
  });
}

export function getOffsetLeft(elem: HTMLElement | null) {
  if (!elem) return 0;

  let offsetLeft = 0;
  do {
    if (!isNaN(elem.offsetLeft)) {
      offsetLeft += elem.offsetLeft;
    }
  } while ((elem = elem.offsetParent as HTMLElement | null) != null);
  return offsetLeft;
}

export function getOffsetTop(elem: HTMLElement | null) {
  if (!elem) return 0;

  let offsetTop = 0;
  do {
    if (!isNaN(elem.offsetTop)) {
      offsetTop += elem.offsetTop;
    }
  } while ((elem = elem.offsetParent as HTMLElement | null) != null);
  return offsetTop;
}

export class AutoCompleteUtils {
  static createAbsoluteDropdown(source: HTMLElement, inputField: HTMLInputElement) {
    source.style.position = 'absolute';
    source.style.top = `${getOffsetTop(inputField) + inputField.getBoundingClientRect().height}px`;
    source.style.left = `${getOffsetLeft(inputField)}px`;
    source.style.width = `${inputField.getBoundingClientRect().width}px`;
    source.removeAttribute('id');
  }

  static createResultItem(data: any, source: HTMLLinkElement) {
    source.innerHTML = data.match;
    source.removeAttribute('id');
    source.href = '#!';
    source.dataset.turbo = 'false';
  }

  static bindDefaultKeyEvents(event: KeyboardEvent) {
    if (event.key === 'Enter') {
      event.preventDefault();
    }
  }
}

export function getOuterHeight(el: HTMLElement) {
  let height = el.offsetHeight;
  let style = getComputedStyle(el);

  height += parseInt(style.marginTop) + parseInt(style.marginBottom);
  return height;
}

export function formatCurrency(currency: number) {
  const globalAny:any = global;

  let locale = '';
  let currencyCode = '';
  switch (globalAny.I18n.locale) {
    case('cs'):
      locale = 'cs-CZ';
      currencyCode = 'CZK';
      break;
    case('sk'):
      locale = 'sk-SK';
      currencyCode = 'EUR'
      break;
    case('pl'):
      locale = 'pl-PL';
      currencyCode = 'PLN';
      break;
    default:
      console.error(`Unknown locale: ${globalAny.I18n.locale}`)
      locale = 'en-EN';
      currencyCode = 'USD';
  }
  return currency.toLocaleString(locale, {style: 'currency', currency: currencyCode})
}

export function fadeOutEffect(fadeTarget: HTMLElement, duration: number) {
  let opacity = Number.parseInt(fadeTarget.style.opacity)
  const fadeEffect = setInterval(function () {
    if (opacity > 0) {
      opacity -= 0.1;
      fadeTarget.style.opacity = opacity.toString();
    } else {
      fadeTarget.style.display = 'none';
      clearInterval(fadeEffect);
    }
  }, duration / (opacity * 10));
}

export function fadeInEffect(fadeTarget: HTMLElement, duration: number, targetOpacity = 1) {
  let opacity = 0;
  fadeTarget.style.opacity = '0';
  fadeTarget.style.display = '';
  const fadeEffect = setInterval(function () {
    if (opacity < targetOpacity) {
      opacity += 0.1
      fadeTarget.style.opacity = opacity.toString();
    } else {
      fadeTarget.style.opacity = targetOpacity.toString();
      clearInterval(fadeEffect);
    }
  }, duration / (targetOpacity * 10));
}

export function setSelectValue(select: any, value: string) {
  let idx = [...select.options].indexOf( select.querySelector(`option[value="${value}"]`) )
  select.selectedIndex = idx;
  select.M_FormSelect.destroy;
  M.FormSelect.init(select);
  let event = new Event('change');
  select.dispatchEvent(event);
}
