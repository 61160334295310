import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['checkbox','dataArray']
  checkedIds = {}

  company_selected = '';

  checkbox_cart_quotes()
  {
    this.checkbox_cart('quotes', 'quote-row', 'quotes_to_invoice_')
  }

  checkbox_cart_payouts()
  {
    this.checkbox_cart('payouts', 'payout-row', 'payouts_ids_')
  }

  checkbox_cart(location, row, field)
  {
    let checkbox = event.target;
    var cart_row = document.querySelector("." + row + "[name='" + checkbox.value + "']");
    if((location == 'quotes') && (this.company_selected == ''))
    {
      this.company_selected = cart_row.children[1].innerText;
    }

    if((location == 'payouts') || (this.company_selected == cart_row.children[1].innerText))
    {
      if (checkbox.checked) {
        this.checkedIds[checkbox.value] = true;
      } else {
        delete this.checkedIds[checkbox.value];

        if((location == 'quotes') && (Object.keys(this.checkedIds).length == 0))
        {
          this.company_selected = '';
        }
      }


      cart_row.classList.toggle('hide');
      var field = document.querySelector("#" + field + "[value='" + checkbox.value + "']");
      if(field)
      {
        field.disabled = !field.disabled;
      }

      var selected_items = document.querySelectorAll("." + row);
      var total_element = document.querySelector('.' + location + '-total-pay');
      var total_value = 0;
      for(var j = 0; j < selected_items.length; j++)
      {
        if(selected_items[j].classList.contains('hide') == false)
        {
          total_value += parseFloat(selected_items[j].querySelector(".price-row").innerText);
        }
      }

      total_element.innerText = 'Celkem: ' + total_value;

      var new_data = [];
      for(var i = 0; i < Object.keys(this.checkedIds).length; i++)
      {
        new_data.push(parseInt(Object.keys(this.checkedIds)[i]));
      }
      this.dataArrayTarget.value = JSON.stringify(new_data);
    }
    else if(location == 'quotes')
    {
      M.toast({html: 'Nelze fakturovat nabídky z různých firem'})
      checkbox.checked = false;
    }
  }

  checkbox_changed() {
    let checkbox = event.target
    if (checkbox.checked) {
      this.checkedIds[checkbox.value] = true
    } else {
      delete this.checkedIds[checkbox.value]
    }

    this.dataArrayTarget.value = JSON.stringify(Object.keys(this.checkedIds));
  }

  checkboxTargetConnected(checkbox) {
    if (this.checkedIds[checkbox.value]) {
      checkbox.checked = true
    }
  }
}
