import M from 'materialize-css'

export default {
  notice(message) {
    M.toast({
      html: message,
      classes: 'light-green',
      displayLength: 10000
    })
  },

  error(message) {
    M.toast({
      html: message,
      classes: 'red darken-1',
      displayLength: 10000
    })
  }
}
