import {DataTable} from "simple-datatables";
import {Spinner} from "./spinner";
import {CONTACT} from "./contacts";
import {JSTable} from "jstable";
import {TASK} from "./tasks";

export {PAYOUT};

const PAYOUT = {}
PAYOUT.dataTable = null;
PAYOUT.amount = 0.0;
PAYOUT.checkboxes = {};
PAYOUT.check_all = [];
PAYOUT.current_page = 0;

PAYOUT.checkTaskBoxes = (allTasksCheckbox) => {
  PAYOUT.check_all[PAYOUT.current_page] = !PAYOUT.check_all[PAYOUT.current_page];
  let taskBoxes = document.querySelectorAll('.payout-checkbox');
  //PAYOUT.amount = 0.0;
  taskBoxes.forEach(box => {
    var previous_check = box.checked;
    box.checked = PAYOUT.check_all[PAYOUT.current_page];
    PAYOUT.checkboxes[box.id].checked = box.checked;
    if(previous_check != PAYOUT.check_all[PAYOUT.current_page])
    {
      if (PAYOUT.check_all[PAYOUT.current_page])
      {
        PAYOUT.amount += parseInt(box.closest('tr').querySelector('.task-amount').innerHTML);
      }
      else
      {
        PAYOUT.amount -= parseInt(box.closest('tr').querySelector('.task-amount').innerHTML);
      }
    }
  })
  document.querySelector('.payout-checkbox').dispatchEvent(new Event("change"));
  document.querySelector('#payout-total-amount').innerHTML = PAYOUT.amount;
}

PAYOUT.updatePayoutAmountCounter = () => {
  for(var checkbox in PAYOUT.checkboxes)
  {
    var box = document.getElementById(checkbox);
    if(box)
    {
      box.checked = PAYOUT.checkboxes[checkbox].checked;
      box.addEventListener('change', e => {
        PAYOUT.amount = 0.0;

        var target = e.target;
        let checkedIds = []
        PAYOUT.checkboxes[target.id].checked = target.checked;
        for(var checkbox in PAYOUT.checkboxes)
        {
          if(PAYOUT.checkboxes[checkbox].checked)
          {
            PAYOUT.amount += PAYOUT.checkboxes[checkbox].amount;
            checkedIds.push( PAYOUT.checkboxes[checkbox].id );
          }
        }

        document.querySelector('#payout_selected_payout_tasks').value = JSON.stringify(checkedIds);
        document.querySelector('#payout-total-amount').innerHTML = PAYOUT.amount;
      });
    }
  }
  var total_amount = document.querySelector('#payout-total-amount');
  if(total_amount)
  {
    total_amount.innerHTML = PAYOUT.amount;
  }
}

PAYOUT.initPayoutAmountCounter = () => {
  let amountCounter = document.querySelector('#payout-total-amount');
  if(amountCounter != null){
    const boxes = document.querySelectorAll('.payout-checkbox');
    boxes.forEach( box => {
      var box_amount = parseInt(box.closest('tr').querySelector('.task-amount').innerHTML);
      //box.closest('tr').querySelector('.task-amount').innerHTML = box_amount + ",00 Kč";
      PAYOUT.checkboxes[box.id] = { amount: box_amount, checked: box.checked, id: parseInt(box.value) };
      if(box.checked)
      {
        PAYOUT.amount += box_amount;
      }
    });
  }
}

PAYOUT.init = () => {
  PAYOUT.dataTable = null;
  PAYOUT.amount = 0.0;
  PAYOUT.checkboxes = {};
  PAYOUT.check_all = [];
  PAYOUT.current_page = 0;
  PAYOUT.initPayoutAmountCounter();

  let userTable = document.querySelector('.payouts-datatable');
  if (userTable != null) {
    let originalTable = userTable.cloneNode(true);
    let nonSortableCells = [...userTable.querySelectorAll('th')].map(function(element, index){
      if (element.dataset.sortable == 'false') return index
    }).filter(Number);
    let sortedCol = userTable.querySelector('th[data-sorted]');
    let sortedColIdx = [...userTable.querySelectorAll('thead th')].findIndex(function(el){ return el == sortedCol});
    let sortedColDirection = 'desc';
    let deferLoading = (userTable.dataset.count == undefined) ? null : userTable.dataset.count

    if (sortedColIdx == -1) {
      sortedColIdx = 0;
    } else {
      sortedColDirection = sortedCol.dataset.sorted;
    }

    var parse_string_params = function(params) {
      var object = {};
      for (var i = 0; i < params.length-1; i++)
      {
        object[params[i]] = params[i+1];
      }
      return object;
    }

    var padTo2Digits = function(num) {
      return num.toString().padStart(2, '0');
    }

    var formatDate = function(date) {
      return [
        padTo2Digits(date.getDate()),
        padTo2Digits(date.getMonth() + 1),
        date.getFullYear(),
      ].join('.');
    }

    var dateRenderer = function (cell, idx) {
      let data = cell.innerHTML;
      if(data == '')
      {
        return '';
      }
      return formatDate(new Date(parseInt(data)*1000));
    }

    var currencyRenderer = function (cell, idx) {
      let data = cell.innerHTML;
      if(data[1] == 'n')
      {
        data = data.split('\n')[1];
      }
      var formated = [];
      if(data == '')
      {
        return '';
      }

      var decimal_length = 0;
      for(var i = data.length-1; i >= 0; i--)
      {
        if(data[i] == '.')
        {
          if(decimal_length == 1)
          {
            data += '0';
          }
          break;
        }
        else
        {
          decimal_length++;
        }
      }

      data = data.slice(0, data.length - (decimal_length-2));
      data = data.replace('.', ',');

      for(var i = data.length-1; i >= 0; i--)
      {
        var length_difference = (data.length - i);

        if ((data.length - (i)) % 3 == 0 && i < data.length-3)
        {
          formated[i] = ' ' + data[i];
        }
        else
        {
          formated[i] = data[i];
        }
      }
      var return_string = '';
      for(var i = 0; i < formated.length; i++)
      {
        return_string += formated[i];
      }
      return return_string + " Kč";
    }


    var columns = [{
      select: nonSortableCells,
      sortable: false
    }]

    var theads = userTable.querySelectorAll('th');
    for(var i = 0; i < theads.length; i++)
    {
      var thead = theads[i];
      var column = {};
      column.select = [i];
      column.sortable = true;
      if(sortedColIdx == i)
      {
        column.sort = sortedColDirection;
      }
      column.render = thead.dataset.rendermethod ? eval(thead.dataset.rendermethod) : function(cell, idx){ return cell.innerHTML };

      columns.push(column);
    }

    PAYOUT.datatable = new JSTable(userTable, {
      labels: {
        perPage: 'Zobrazit záznamů: {select}',
        noRows: 'Žadná data',
        placeholder: 'Hledat...',
        info: 'Zobrazuji {start} až {end} z {rows} záznamů',
        loading: 'Načítám data'
      },
      perPage: 20,
      perPageSelect: [5, 10, 20, 40, 60, 80, 100],
      columns: columns,
      searchable: true,
      serverSide: userTable.dataset.url != undefined,
      classes: { search: 'dataTable-search', dropdown: 'dataTable-dropdown', input: 'dataTable-input' },
      ajax: userTable.dataset.url,
      ajaxParams: userTable.dataset.params == undefined ? {} : parse_string_params(userTable.dataset.params.split(",")),
      deferLoading: deferLoading
    });
    if(PAYOUT.datatable.config.classes.search == 'hidden')
    {
      PAYOUT.datatable.search(' ');
    }
    // No idea why wrapper is sometimes undefined
    if (PAYOUT.datatable.wrapper) {
      PAYOUT.datatable.originalTable = originalTable;
      M.FormSelect.init(PAYOUT.datatable.wrapper.querySelector('select'));
    }

    const allTasksCheckbox = document.querySelector('#payout_all_tasks');
    if(allTasksCheckbox != null){
      allTasksCheckbox.addEventListener('click', e => {
        PAYOUT.checkTaskBoxes(e.target);
      })
    }

    var tooltip_init = function () {
      let tooltipped = PAYOUT.datatable.wrapper.querySelectorAll('.tooltipped');
      M.Tooltip.init(tooltipped);
      PAYOUT.updatePayoutAmountCounter();
    }
    tooltip_init();

    var tooltip_cleanup = function () {
      let tooltipped = PAYOUT.datatable.wrapper.querySelectorAll('.tooltipped');
      tooltipped.forEach(tooltip => {
        const instance = M.Tooltip.getInstance(tooltip);
        if (instance != null) instance.destroy();
      });
    }

    PAYOUT.datatable.on('update', function(){ tooltip_init();});
    PAYOUT.datatable.on('fetchData', tooltip_cleanup);
    PAYOUT.datatable.on('paginate', function(old_page, new_page) {
      PAYOUT.current_page = new_page-1;
      let url = window.location.pathname + window.location.search
      Turbolinks.controller.replaceHistoryWithLocationAndRestorationIdentifier(location, Turbolinks.uuid())
    });
    PAYOUT.datatable.on("perPageChange", function (old_value, new_value) {
      PAYOUT.check_all = [];
      for(var i = 0; i < Math.ceil(Object.keys(PAYOUT.checkboxes).length/new_value); i++)
      {
        PAYOUT.check_all[i] = false;
      }
    });
  }

  const userFilter = document.querySelector('#payouts-user-filter');
  if(userFilter != null){
    userFilter.addEventListener('change', e => {
      const target = e.target;
      target.disabled = true;
      M.FormSelect.getInstance(target).destroy();
      M.FormSelect.init(target);
      target.closest('form').appendChild(Spinner.createLoadingCircle());
      Turbolinks.visit(`/payouts?user_id=${target.value}`);
    })
  }
  const newPayoutButton = document.querySelector('#new-payout-button');
  if(newPayoutButton != null){
    newPayoutButton.addEventListener('click', e => {
      e.preventDefault();
      if(userFilter.value !== '') {
        Turbolinks.visit(`/payouts/new?user_id=${userFilter.value}`);
      }else{
        alert('Musíte vybrat uživatele!');
      }
    })
  }


}

PAYOUT.beforeCacheClear = () => {
  if (PAYOUT.dataTable != null) {
    PAYOUT.dataTable.destroy();
    PAYOUT.dataTable = null
  }
}
