import {DataTable} from "simple-datatables";
import {initClickableRows} from "./utils";
import {JSTable} from "jstable";
import {TASK} from "./tasks";

export {SITE};

const SITE = {};
SITE.dataTable = null;

// This prevent duplicate load of datatables function when we forward/backward
SITE.beforeCacheClear = function(){
    if (SITE.dataTable != null) {
        SITE.dataTable.destroy();
        SITE.dataTable = null
    }
}

SITE.init = function() {
    let siteTable = document.querySelector('.sites_datatable');
    if (siteTable != null) {
        let originalTable = siteTable.cloneNode(true);
        let nonSortableCells = [...siteTable.querySelectorAll('th')].map(function(element, index){
            if (element.dataset.sortable == 'false') return index
        }).filter(Number);
        let sortedCol = siteTable.querySelector('th[data-sorted]');
        let sortedColIdx = [...siteTable.querySelectorAll('thead th')].findIndex(function(el){ return el == sortedCol});
        let sortedColDirection = 'asc';
        let deferLoading = (siteTable.dataset.count == undefined) ? null : siteTable.dataset.count

        if (sortedColIdx == -1) {
            sortedColIdx = 0;
        } else {
            sortedColDirection = sortedCol.dataset.sorted;
        }

        var parse_string_params = function(params) {
            var object = {};
            for (var i = 0; i < params.length-1; i++)
            {
                object[params[i]] = params[i+1];
            }
            return object;
        }

        var padTo2Digits = function(num) {
            return num.toString().padStart(2, '0');
        }

        var formatDate = function(date) {
            return [
                padTo2Digits(date.getDate()),
                padTo2Digits(date.getMonth() + 1),
                date.getFullYear(),
            ].join('.');
        }

        var dateRenderer = function (cell, idx) {
            let data = cell.innerHTML;
            if(data == '')
            {
                return '';
            }
            return formatDate(new Date(parseInt(data)*1000));
        }


        var columns = [{
            select: nonSortableCells,
            sortable: false
        }]

        var theads = siteTable.querySelectorAll('th');
        for(var i = 0; i < theads.length; i++)
        {
            var thead = theads[i];
            var column = {};
            column.select = [i];
            column.sortable = true;
            if(sortedColIdx == i)
            {
                column.sort = sortedColDirection;
            }
            column.render = thead.dataset.rendermethod ? eval(thead.dataset.rendermethod) : function(cell, idx){ return cell.innerHTML };

            columns.push(column);
        }

        SITE.datatable = new JSTable(siteTable, {
            labels: {
                perPage: 'Zobrazit záznamů: {select}',
                noRows: 'Žadná data',
                placeholder: 'Hledat...',
                info: 'Zobrazuji {start} až {end} z {rows} záznamů',
                loading: 'Načítám data'
            },
            perPage: 20,
            perPageSelect: [5, 10, 20, 40, 60, 80, 100],
            columns: columns,
            searchable: true,
            serverSide: siteTable.dataset.url != undefined,
            classes: { search: 'dataTable-search', dropdown: 'dataTable-dropdown', input: 'dataTable-input' },
            ajax: siteTable.dataset.url,
            ajaxParams: siteTable.dataset.params == undefined ? {} : parse_string_params(siteTable.dataset.params.split(",")),
            deferLoading: deferLoading
        });
        if(SITE.datatable.config.classes.search == 'hidden')
        {
            SITE.datatable.search(' ');
        }
        // No idea why wrapper is sometimes undefined
        if (SITE.datatable.wrapper) {
            SITE.datatable.originalTable = originalTable;
            M.FormSelect.init(SITE.datatable.wrapper.querySelector('select'));
        }
        var tooltip_init = function () {
            let tooltipped = SITE.datatable.wrapper.querySelectorAll('.tooltipped');
            M.Tooltip.init(tooltipped);
        }
        tooltip_init();

        var tooltip_cleanup = function () {
            let tooltipped = SITE.datatable.wrapper.querySelectorAll('.tooltipped');
            tooltipped.forEach(tooltip => {
                const instance = M.Tooltip.getInstance(tooltip);
                if (instance != null) instance.destroy();
            });
        }

        SITE.datatable.on('update', tooltip_init);
        SITE.datatable.on('fetchData', tooltip_cleanup);
        SITE.datatable.on('paginate', function() {
            let url = window.location.pathname + window.location.search
            Turbolinks.controller.replaceHistoryWithLocationAndRestorationIdentifier(location, Turbolinks.uuid())
        });
        SITE.datatable.on('init', () => {
            initClickableRows(SITE.datatable.wrapper);
        });
    }
}
