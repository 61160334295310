import { Controller } from "@hotwired/stimulus"

export default class extends Controller
{
    static targets = [ "wrapper" ]

    connect()
    {
        let that = this;
        var date = new Date();
        this.wrapperTarget.scrollLeft = 60*(date.getDate()-1);
        setTimeout(function(){
            if(that.element.classList.contains('to-be-hidden'))
            {
                that.element.classList.remove('to-be-hidden');
                that.element.classList.add('hide');
            }
        }, 50);

        console.log("Hello, Stimulus!", this.element)
    }
}