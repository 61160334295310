import '../stylesheets/application.scss';
// eslint-disable-next-line no-undef
import * as ActiveStorage from '@rails/activestorage'
import M from "materialize-css"
import {DataTable} from 'simple-datatables'
import {initPlugin} from '../javascript/backend_tables'
initPlugin(DataTable);

require("@rails/ujs").start();
require("turbolinks").start();
require.context('../images', true);

DataTable.labels = {
  perPage: 'Zobraz záznamů: {select}',
  noRows: 'Tabulka neobsahuje žádná data',
  placeholder: 'Hledat...',
  info: 'Zobrazuji {start} až {end} z {rows} záznamů'
}

M.Datepicker.defaults.i18n = {
  months: ['leden', 'únor', 'březen', 'duben', 'květen', 'červen', 'červenec', 'srpen', 'září', 'říjen', 'listopad', 'prosinec'],
  monthsShort: ['led', 'úno', 'bře', 'dub', 'kvě', 'čer', 'čvc', 'srp', 'zář', 'říj', 'lis', 'pro'],
  weekdays: ['neděle', 'pondělí', 'úterý', 'středa', 'čtvrtek', 'pátek', 'sobota'],
  weekdaysShort: ['ne', 'po', 'út', 'st', 'čt', 'pá', 'so'],
  weekdaysAbbrev: ['N', 'P', 'Ú', 'S', 'Č', 'P', 'S'],
  cancel: 'Zavřít',
  clear: 'Vymazat',
  done: 'Potvrdit'
}

M.Timepicker.defaults.i18n = {
  cancel: 'Zavřít',
  clear: 'Vymazat',
  done: 'Potvrdit'
}

M.Datepicker.defaults.format = "yyyy-mm-dd";

import "javascript/controllers"

import {mattDestroy, mattInit} from "../javascript/matt_init";

ActiveStorage.start();
global.L = require("leaflet");
global.DataTable = DataTable;

import {AUTOCOMPLETE} from "../javascript/autocomplete";
import {CONTACT} from "../javascript/contacts";
import {DASHBOARD} from "../javascript/dashboard";
import {MATERIAL} from "../javascript/materials";
import {PAYOUT} from "../javascript/payouts";
import {PROJECT} from "../javascript/projects";
import {QUOTE} from "../javascript/quote";
import {SITE} from "../javascript/sites";
import {TASK} from "../javascript/tasks";
import {datatableRegistry} from "../javascript/initialize";
import {app_init} from "../javascript/initialize";

document.addEventListener('turbolinks:load', () => {
  mattInit()
  app_init()

  TASK.init();
  CONTACT.init();
  DASHBOARD.init();
  MATERIAL.init();
  PROJECT.init();
  SITE.init();
  PAYOUT.init();
  AUTOCOMPLETE.init();
  QUOTE.init();
});

document.addEventListener('turbolinks:before-cache', () => {
  mattDestroy(document.body);

  if((TASK.datatable != undefined) && (TASK.datatable.wrapper.parentElement != null))
  {
    TASK.datatable.wrapper.parentElement.replaceChild(TASK.datatable.originalTable, TASK.datatable.wrapper);
  }
  if((CONTACT.datatable != undefined) && (CONTACT.datatable.wrapper.parentElement != null))
  {
    CONTACT.datatable.wrapper.parentElement.replaceChild(CONTACT.datatable.originalTable, CONTACT.datatable.wrapper);
  }
  if((DASHBOARD.datatable != undefined) && (DASHBOARD.datatable.wrapper.parentElement != null))
  {
    DASHBOARD.datatable.wrapper.parentElement.replaceChild(DASHBOARD.datatable.originalTable, DASHBOARD.datatable.wrapper);
  }
  if((SITE.datatable != undefined) && (SITE.datatable.wrapper.parentElement != null))
  {
    SITE.datatable.wrapper.parentElement.replaceChild(SITE.datatable.originalTable, SITE.datatable.wrapper);
  }
  if((PAYOUT.datatable != undefined) && (PAYOUT.datatable.wrapper.parentElement != null))
  {
    PAYOUT.datatable.wrapper.parentElement.replaceChild(PAYOUT.datatable.originalTable, PAYOUT.datatable.wrapper);
  }
  if((MATERIAL.datatable != undefined) && (MATERIAL.datatable.wrapper.parentElement != null))
  {
    MATERIAL.datatable.wrapper.parentElement.replaceChild(MATERIAL.datatable.originalTable, MATERIAL.datatable.wrapper);
  }
  if((MATERIAL.sapDataTable != undefined) && (MATERIAL.sapDataTable.wrapper.parentElement != null))
  {
    MATERIAL.sapDataTable.wrapper.parentElement.replaceChild(MATERIAL.sapDataTable.originalTable, MATERIAL.sapDataTable.wrapper);
  }

  for(var i = 0; i < datatableRegistry.length; i++)
  {
    datatableRegistry[i].wrapper.parentElement.replaceChild(datatableRegistry[i].originalTable, datatableRegistry[i].wrapper);
  }

  PROJECT.beforeCacheClear();
});
