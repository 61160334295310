export {PROJECT}

const PROJECT = {};
PROJECT.dataTable = null;

PROJECT.toggleTemplateSelect = (hide) => {
  const templateSelect = document.querySelector('#dir-structure-select');
  if(hide){
    templateSelect.querySelector('input').disabled = true
    templateSelect.style.display = 'none'
  }else{
    templateSelect.querySelector('input').disabled = false
    templateSelect.style.display = 'block'
  }
}

// This prevent duplicate load of datatables function when we forward/backward
PROJECT.beforeCacheClear = function() {
    if (PROJECT.dataTable != null) {
        PROJECT.dataTable.destroy();
        PROJECT.dataTable = null
    }
}

PROJECT.init = function() {
  const createStructureCheckbox = document.querySelector('#project_create_folder_on_cloud');
  if (createStructureCheckbox != null){
    PROJECT.toggleTemplateSelect(!createStructureCheckbox.checked);
    createStructureCheckbox.addEventListener('change', () => {
      PROJECT.toggleTemplateSelect(!createStructureCheckbox.checked);
    });
  }
}
