import {Controller} from 'stimulus'

export default class extends Controller {
  connect() {
    this.text = this.element.textContent
    this.hidden = false
    this.toggle()
  }

  toggle() {
    if (this.hidden) {
      this.element.textContent = this.text
      this.hidden = false
    } else {
      this.element.textContent = '*'.repeat(this.text.length)
      this.hidden = true
    }
  }
}
