import { Controller } from "stimulus"
import BestInStimulus from '../best_in_stimulus'

export default class extends Controller {
  static values = {
    inputType: String,
    object: String,
    input: String,
    inputId: String,
    attribute: String,
    innerClass: String,
    okButton: String,
    okButtonClass: String,
    url: String,
    placeHolder: String,
    htmlAttrs: Object,
    collection: Array,
    reloadOnSuccess: Boolean,
    turbo: Boolean,
    closeOnFocusOut: Boolean
  }

  connect() {
    this.initBipFormPtr = this.initBipForm.bind(this);
    this.element.style.cursor = 'pointer';
    this.element.addEventListener('click', this.initBipFormPtr);
  }

  initBipForm() {
    const inputFieldName = `${this.objectValue}[${this.attributeValue}]`;
    const inputFieldId = `${this.objectValue}_${this.attributeValue}`;
    const bestInStimulus = new BestInStimulus(
      {
        element: this.element,
        url: this.urlValue,
        inputId: this.inputIdValue,
        inputFieldName: inputFieldName,
        inputFieldId: inputFieldId,
        collection: this.collectionValue,
        inputValue: this.inputValue,
        innerClass: this.innerClassValue,
        placeHolder: this.placeHolderValue,
        okButton: this.okButtonValue,
        okButtonClass: this.okButtonClassValue,
        reloadOnSuccess: this.reloadOnSuccessValue,
        turbo: this.turboValue,
        closeOnFocusOut: this.closeOnFocusOutValue
      }
    )
    if(this.inputTypeValue === 'checkbox'){
      bestInStimulus.initCheckbox();
      return;
    }

    const formTag = bestInStimulus.initHelperForm()
    switch (this.inputTypeValue) {
      case 'select':
        bestInStimulus.createSelectOption();
        break;
      case 'input':
        bestInStimulus.createInputFieldOption();
        break;
      case 'textarea':
        bestInStimulus.createTextAreaOption();
        break;
      case 'number':
        bestInStimulus.createNumberFieldOption();
        break;
      default:
        console.error(`ERROR: Unknown input type ${this.inputTypeValue}`);
        return;
    }
    this.element.innerHTML = '';
    this.element.appendChild(formTag);
    bestInStimulus.initFormSubmit();
    if (this.hasHtmlAttrsValue) {
      bestInStimulus.addCustomHtmlAttrs(this.htmlAttrsValue);
    }
    bestInStimulus.focus()
    this.element.removeEventListener('click', this.initBipFormPtr);
  }
}
