import {DataTable} from "simple-datatables";
import {initClickableRows} from "./utils";
import {Spinner} from './spinner'
import {JSTable} from "jstable";
import {TASK} from "./tasks";

export {MATERIAL}

const MATERIAL = {};
MATERIAL.dataTable = null;
MATERIAL.sapDataTable = null;

MATERIAL.copyLastRow = function() {
  const rows = document.querySelectorAll('.import-rows .row');
  const lastRow = rows[rows.length - 1];
  const taskId = lastRow.querySelector('[name="material[task[ids]][]"]').value;
  const location = lastRow.querySelector('[name="material[locations][]"]').value;
  const params = taskId == '' ? `&location=${location}` : `&task_id=${taskId}&location=${location}`
  MATERIAL.addBatchImportRow(params);
}

MATERIAL.addBatchImportRow = function(params = null){
  let wrapper = document.querySelector('.import-rows');
  let rows = wrapper.querySelectorAll('.row');
  fetch(`/materials/new_batch_import_row?row=${rows.length}${params}`, {
    headers: {
      Accept: 'application/javascript'
    }
  }).then(resp => {
    if(resp.status === 200){
      return resp.text();
    }else{
      alert('Nepodařilo se načíst další řádku');
      return null;
    }
  }).then(data => {
    if(data != null){
      eval(data);
    }
  }).then(() => {
    let inputs = document.querySelector('#add-batch-import-row').closest('form').querySelectorAll('input');
    inputs.forEach(input => {
      input.removeEventListener('keypress', MATERIAL.FocusNextOnEnter);
      input.addEventListener('keypress', MATERIAL.FocusNextOnEnter);
    })
  })
}

MATERIAL.FocusNextOnEnter = function (e){
  if (e.key === "Enter") {
    e.preventDefault();
    MATERIAL.focusNextInput(e.target);
  }
}

MATERIAL.focusNextInput = function(input) {
  const nextInput = input.closest('.col').nextElementSibling.querySelector('input')
  if (nextInput) nextInput.focus()
}

MATERIAL.initBatchImportRows = function (){
  const btn = document.querySelector('#add-batch-import-row');
  if(btn != null){
    const inputs = btn.closest('form').querySelectorAll('input');
    inputs.forEach(input => {
      input.addEventListener('keypress', MATERIAL.FocusNextOnEnter)
    })
    btn.addEventListener('click', () => MATERIAL.addBatchImportRow());
  }

  const copyRowButton = document.querySelector('#copy-row');
  if (copyRowButton) {
    copyRowButton.addEventListener('click', () => MATERIAL.copyLastRow())
  }
}

MATERIAL.init = function () {
  // Allow removing the first row
  const firstRowRemoveButton = document.querySelector('.remove-row-button')
  if (firstRowRemoveButton) {
    firstRowRemoveButton.addEventListener('click', function() {
      document.querySelector('.import-rows').removeChild(firstRowRemoveButton.closest('.row'))
    })
  }

  const filterButtons = document.querySelectorAll('.table-filter')
  filterButtons.forEach(filter =>
    filter.addEventListener('click', () => {
      filterButtons.forEach(button => {
        button.classList.remove('btn-primary')
        button.classList.add('btn-default')
      })
      filter.classList.remove('btn-default')
      filter.classList.add('btn-primary')
  }))


  let materialTable = document.querySelector('.materials_datatable');
  if (materialTable != null) {
    let originalTable = materialTable.cloneNode(true);
    let nonSortableCells = [...materialTable.querySelectorAll('th')].map(function(element, index){
      if (element.dataset.sortable == 'false') return index
    }).filter(Number);
    let sortedCol = materialTable.querySelector('th[data-sorted]');
    let sortedColIdx = [...materialTable.querySelectorAll('thead th')].findIndex(function(el){ return el == sortedCol});
    let sortedColDirection = 'asc';
    let deferLoading = (materialTable.dataset.count == undefined) ? null : materialTable.dataset.count

    if (sortedColIdx == -1) {
      sortedColIdx = 0;
    } else {
      sortedColDirection = sortedCol.dataset.sorted;
    }

    var parse_string_params = function(params) {
      var object = {};
      for (var i = 0; i < params.length-1; i++)
      {
        object[params[i]] = params[i+1];
      }
      return object;
    }

    var padTo2Digits = function(num) {
      return num.toString().padStart(2, '0');
    }

    var formatDate = function(date) {
      return [
        padTo2Digits(date.getDate()),
        padTo2Digits(date.getMonth() + 1),
        date.getFullYear(),
      ].join('.');
    }

    var dateRenderer = function (cell, idx) {
      let data = cell.innerHTML;
      if(data == '')
      {
        return '';
      }
      return formatDate(new Date(parseInt(data)*1000));
    }


    var columns = [{
      select: nonSortableCells,
      sortable: false
    }]

    var theads = materialTable.querySelectorAll('th');
    for(var i = 0; i < theads.length; i++)
    {
      var thead = theads[i];
      var column = {};
      column.select = [i];
      column.sortable = true;
      if(sortedColIdx == i)
      {
        column.sort = sortedColDirection;
      }
      column.render = thead.dataset.rendermethod ? eval(thead.dataset.rendermethod) : function(cell, idx){ return cell.innerHTML };

      columns.push(column);
    }

    MATERIAL.datatable = new JSTable(materialTable, {
      labels: {
        perPage: 'Zobrazit záznamů: {select}',
        noRows: 'Žadná data',
        placeholder: 'Hledat...',
        info: 'Zobrazuji {start} až {end} z {rows} záznamů',
        loading: 'Načítám data'
      },
      perPage: 20,
      perPageSelect: [5, 10, 20, 40, 60, 80, 100],
      columns: columns,
      searchable: true,
      serverSide: materialTable.dataset.url != undefined,
      classes: { search: 'dataTable-search', dropdown: 'dataTable-dropdown', input: 'dataTable-input' },
      ajax: materialTable.dataset.url,
      ajaxParams: materialTable.dataset.params == undefined ? {} : parse_string_params(materialTable.dataset.params.split(",")),
      deferLoading: deferLoading
    });
    if(MATERIAL.datatable.config.classes.search == 'hidden')
    {
      MATERIAL.datatable.search(' ');
    }

    // No idea why wrapper is sometimes undefined
    if (MATERIAL.datatable.wrapper) {
      MATERIAL.datatable.originalTable = originalTable;
      M.FormSelect.init(MATERIAL.datatable.wrapper.querySelector('select'));
    }
    var tooltip_init = function () {
      let tooltipped = MATERIAL.datatable.wrapper.querySelectorAll('.tooltipped');
      M.Tooltip.init(tooltipped);
    }
    tooltip_init();

    var tooltip_cleanup = function () {
      let tooltipped = MATERIAL.datatable.wrapper.querySelectorAll('.tooltipped');
      tooltipped.forEach(tooltip => {
        const instance = M.Tooltip.getInstance(tooltip);
        if (instance != null) instance.destroy();
      });
    }

    MATERIAL.datatable.on('update', tooltip_init);
    MATERIAL.datatable.on('fetchData', tooltip_cleanup);
    MATERIAL.datatable.on('paginate', function() {
      let url = window.location.pathname + window.location.search
      Turbolinks.controller.replaceHistoryWithLocationAndRestorationIdentifier(location, Turbolinks.uuid())
    });
  }


  let sapTable = document.querySelector('.saps_datatable');
  if (sapTable != null) {
    let originalTable = sapTable.cloneNode(true);
    let nonSortableCells = [...sapTable.querySelectorAll('th')].map(function(element, index){
      if (element.dataset.sortable == 'false') return index
    }).filter(Number);
    let sortedCol = sapTable.querySelector('th[data-sorted]');
    let sortedColIdx = [...sapTable.querySelectorAll('thead th')].findIndex(function(el){ return el == sortedCol});
    let sortedColDirection = 'asc';
    let deferLoading = (sapTable.dataset.count == undefined) ? null : sapTable.dataset.count

    if (sortedColIdx == -1) {
      sortedColIdx = 0;
    } else {
      sortedColDirection = sortedCol.dataset.sorted;
    }

    var parse_string_params = function(params) {
      var object = {};
      for (var i = 0; i < params.length-1; i++)
      {
        object[params[i]] = params[i+1];
      }
      return object;
    }

    var padTo2Digits = function(num) {
      return num.toString().padStart(2, '0');
    }

    var formatDate = function(date) {
      return [
        padTo2Digits(date.getDate()),
        padTo2Digits(date.getMonth() + 1),
        date.getFullYear(),
      ].join('.');
    }

    var dateRenderer = function (cell, idx) {
      let data = cell.innerHTML;
      if(data == '')
      {
        return '';
      }
      return formatDate(new Date(parseInt(data)*1000));
    }


    var columns = [{
      select: nonSortableCells,
      sortable: false
    }]

    var theads = sapTable.querySelectorAll('th');
    for(var i = 0; i < theads.length; i++)
    {
      var thead = theads[i];
      var column = {};
      column.select = [i];
      column.sortable = true;
      if(sortedColIdx == i)
      {
        column.sort = sortedColDirection;
      }
      column.render = thead.dataset.rendermethod ? eval(thead.dataset.rendermethod) : function(cell, idx){ return cell.innerHTML };

      columns.push(column);
    }

    MATERIAL.sapDataTable = new JSTable(sapTable, {
      labels: {
        perPage: 'Zobrazit záznamů: {select}',
        noRows: 'Žadná data',
        placeholder: 'Hledat...',
        info: 'Zobrazuji {start} až {end} z {rows} záznamů',
        loading: 'Načítám data'
      },
      perPage: 20,
      perPageSelect: [5, 10, 20, 40, 60, 80, 100],
      columns: columns,
      searchable: true,
      serverSide: sapTable.dataset.url != undefined,
      classes: { search: 'dataTable-search', dropdown: 'dataTable-dropdown', input: 'dataTable-input' },
      ajax: sapTable.dataset.url,
      ajaxParams: sapTable.dataset.params == undefined ? {} : parse_string_params(sapTable.dataset.params.split(",")),
      deferLoading: deferLoading
    });
    if(MATERIAL.sapDataTable.config.classes.search == 'hidden')
    {
      MATERIAL.sapDataTable.search(' ');
    }

    // No idea why wrapper is sometimes undefined
    if (MATERIAL.sapDataTable.wrapper) {
      MATERIAL.sapDataTable.originalTable = originalTable;
      M.FormSelect.init(MATERIAL.sapDataTable.wrapper.querySelector('select'));
    }
    var tooltip_init = function () {
      let tooltipped = MATERIAL.sapDataTable.wrapper.querySelectorAll('.tooltipped');
      M.Tooltip.init(tooltipped);
    }
    tooltip_init();

    var tooltip_cleanup = function () {
      let tooltipped = MATERIAL.sapDataTable.wrapper.querySelectorAll('.tooltipped');
      tooltipped.forEach(tooltip => {
        const instance = M.Tooltip.getInstance(tooltip);
        if (instance != null) instance.destroy();
      });
    }

    MATERIAL.sapDataTable.on('update', tooltip_init);
    MATERIAL.sapDataTable.on('fetchData', tooltip_cleanup);
    MATERIAL.sapDataTable.on('paginate', function() {
      let url = window.location.pathname + window.location.search
      Turbolinks.controller.replaceHistoryWithLocationAndRestorationIdentifier(location, Turbolinks.uuid())
    });
  }



  MATERIAL.initBatchImportRows();
}
