import M from "materialize-css"
import {getOuterHeight} from "utils";
import TooltipOptions = M.TooltipOptions;
import TabsOptions = M.TabsOptions;
import TimepickerOptions = M.TimepickerOptions;

export function mattInit(container:HTMLElement = document.body) {
  M.updateTextFields()

  // Materialize autoinit seems to sometimes override our inits with custom options
  // destroying any current instances before our init should fix this
  let elems: MElements = container.querySelectorAll('.timepicker');
  elems.forEach(elem => {
    const instance = M.Timepicker.getInstance(elem);
    if (instance != null) instance.destroy();
  })
  M.Timepicker.init(elems, {
    twelveHour: false,
  });

  elems = container.querySelectorAll('.dropdown-trigger');
  elems.forEach(elem => {
    const instance = M.Dropdown.getInstance(elem);
    if (instance != null) instance.destroy();
  })
  M.Dropdown.init(elems, {
    constrainWidth: false,
    coverTrigger: false
  });

  elems = container.querySelectorAll('select:not(.no-autoinit)');
  elems.forEach(elem => {
    const instance = M.FormSelect.getInstance(elem);
    if (instance != null) instance.destroy();
  })
  M.FormSelect.init(elems)
  container.querySelectorAll('select[data-invalid="1"]').forEach(select => {
    const parent = select.closest(".select-wrapper");
    if (parent != null) {
      const matSelect = parent.querySelector("input.select-dropdown")
      if (matSelect != null) matSelect.classList.add("invalid");
    }
  })


  const dateTimePickers = container.querySelectorAll('.datetime');
  dateTimePickers.forEach(dtPicker => {
    initDateTimePicker(dtPicker as HTMLElement)
  });

  const datePickers = container.querySelectorAll('.datepicker');
  datePickers.forEach(dPicker => {
    initDatePicker(dPicker as HTMLElement)
  })

  const sideNavs = container.querySelectorAll('.sidenav');
  sideNavs.forEach(sideNav => {
    const instance = M.Sidenav.getInstance(sideNav);
    if (instance != null) instance.destroy();
  });
  M.Sidenav.init(sideNavs, {
    edge: 'left',
    draggable: true
  });

  const rightSideNavs = container.querySelectorAll('.sidenav-right');
  M.Sidenav.init(rightSideNavs, {
    edge: 'right'
  });

  // Resize textareas if they are prefilled
  const textAreas = container.querySelectorAll('.materialize-textarea');
  textAreas.forEach(area => {
    M.textareaAutoResize(area);
  });

  const tooltips: MElements = container.querySelectorAll('.tooltipped');
  tooltips.forEach(tooltip => {
    const instance = M.Tooltip.getInstance(tooltip);
    if (instance != null) instance.destroy();
  })
  M.Tooltip.init(tooltips as MElements, {
    delay: 50
  } as Partial<TooltipOptions>);

  const carousels = container.querySelectorAll('.carousel.carousel-slider');
  carousels.forEach(carousel => {
    const instance = M.Carousel.getInstance(carousel);
    if (instance != null) instance.destroy();

    const image = carousel.querySelector('img');
    if (image != null) {
      const carouselOptions = {
        fullWidth: true,
        indicators: true,
        noWrap: true,
        numVisible: 1
      }

      if (image.complete) {
        M.Carousel.init(carousel, carouselOptions)
      } else {
        image.addEventListener('load', () => M.Carousel.init(carousel, carouselOptions))
      }
    }
  })

  const scrollspies: MElements = container.querySelectorAll('.scrollspy');
  scrollspies.forEach(scrollspy => {
    const instance = M.ScrollSpy.getInstance(scrollspy);
    if (instance != null) instance.destroy();
  })
  M.ScrollSpy.init(scrollspies, {
    scrollOffset: 74
  });

  const collapsibles: MElements = container.querySelectorAll('.collapsible');
  collapsibles.forEach(collapsible => {
    const instance = M.Collapsible.getInstance(collapsible);
    if (instance != null) instance.destroy();
  });
  M.Collapsible.init(collapsibles);

  const modals: MElements = container.querySelectorAll('.modal');
  modals.forEach(modal => {
    const instance = M.Modal.getInstance(modal);
    if (instance != null) instance.destroy();
  });
  M.Modal.init(modals);

  const tabs: MElements = container.querySelectorAll('.tabs:not(.swipeable):not(.no-autoinit)');
  tabs.forEach(tab => {
    const instance = M.Tabs.getInstance(tab);
    if (instance != null) instance.destroy();
  })
  M.Tabs.init(tabs);

  const swipeableTabs:MElements = container.querySelectorAll('.tabs.swipeable');
  swipeableTabs.forEach(tab => {
    const instance = M.Tabs.getInstance(tab);
    if (instance != null) instance.destroy();
  })
  M.Tabs.init(swipeableTabs, {
    swipeable: true,
    onShow: resizeCarouselTabs
  } as Partial<TabsOptions>);

  const counterTextAreas:MElements = container.querySelectorAll('input#customer_name, input#customer_company');
  M.CharacterCounter.init(counterTextAreas);
}

export function initDatePicker(dPicker: HTMLElement) {
  let dateValue: string | null = null;

  M.Datepicker.init(dPicker, {
    format: 'dd. mm. yyyy',
    autoClose: true,
    firstDay: 1,
    onSelect: function () {
      dateValue = this.toString()
    },
  })

  dPicker.addEventListener("change", (event) => {
    if (dateValue)
      (event.target as HTMLFormElement).value = dateValue;
  });
}

export function initDateTimePicker(dtPicker: HTMLElement) {
  let dateValue: string | null = null;
  let timeValue: string | null = null;

  M.Datepicker.init(dtPicker, {
    format: 'dd. mm. yyyy',
    autoClose: true,
    firstDay: 1,
    onSelect: function () {
      dateValue = this.toString()
    },
    onClose: function () {
      const timepicker = M.Timepicker.init(dtPicker, {
        twelveHour: false,
        autoClose: true,
        onSelect: (hours, minutes) => {
          timeValue = `${hours}:${minutes}`;
        },
        onCloseEnd: () => {
          timepicker.destroy()
          dtPicker.blur()
        }
      } as Partial<TimepickerOptions>);
      timepicker.open();
    }
  });

  dtPicker.addEventListener("change", (event) => {
    if (dateValue && timeValue) {
      (event.target as HTMLFormElement).value = dateValue + " " + timeValue;
    }
  });
}

export function resizeCarouselTabs(elem: HTMLElement) {
  let maxHeight = 0;
  if (!elem.parentNode || !elem.parentElement) return;

  elem.parentNode.childNodes.forEach((child: any) => {
    let calcHeight = 0;
    [...child.children].forEach(cchild => {
      calcHeight += getOuterHeight(cchild);
    })
    if (calcHeight > maxHeight) {
      maxHeight = calcHeight;
    }
  })
  elem.parentElement.style.height = maxHeight + 'px';
}

export function mattDestroy(container:HTMLElement = document.body) {
  // Materialize autoinit seems to sometimes override our inits with custom options
  // destroying any current instances before our init should fix this
  let elems = container.querySelectorAll('.timepicker');
  elems.forEach(elem => {
    const instance = M.Timepicker.getInstance(elem);
    if (instance != null) instance.destroy();
  })

  elems = container.querySelectorAll('.dropdown-trigger');
  elems.forEach(elem => {
    const instance = M.Dropdown.getInstance(elem);
    if (instance != null) instance.destroy();
  })

  elems = container.querySelectorAll('select:not(.no-autoinit)');
  elems.forEach(elem => {
    const instance = M.FormSelect.getInstance(elem);
    if (instance != null) instance.destroy();
  })

  const sideNavs = container.querySelectorAll('.sidenav');
  sideNavs.forEach(sideNav => {
    const instance = M.Sidenav.getInstance(sideNav);
    if (instance != null) instance.destroy();
  });

  const tooltips = container.querySelectorAll('.tooltipped');
  tooltips.forEach(tooltip => {
    const instance = M.Tooltip.getInstance(tooltip);
    if (instance != null) instance.destroy();
  })

  const carousels = container.querySelectorAll('.carousel.carousel-slider');
  carousels.forEach(carousel => {
    const instance = M.Carousel.getInstance(carousel);
    if (instance != null) instance.destroy();
  })

  const scrollspies = container.querySelectorAll('.scrollspy');
  scrollspies.forEach(scrollspy => {
    const instance = M.ScrollSpy.getInstance(scrollspy);
    if (instance != null) instance.destroy();
  })

  const collapsibles = container.querySelectorAll('.collapsible');
  collapsibles.forEach(collapsible => {
    const instance = M.Collapsible.getInstance(collapsible);
    if (instance != null) instance.destroy();
  });

  const modals = container.querySelectorAll('.modal');
  modals.forEach(modal => {
    const instance = M.Modal.getInstance(modal);
    if (instance != null) instance.destroy();
  });

  const textAreas:NodeListOf<HTMLTextAreaElement> = container.querySelectorAll('input#customer_name, input#customer_company');
  textAreas.forEach((textArea: HTMLTextAreaElement) => {
    const instance = M.CharacterCounter.getInstance(textArea);
    if(instance != null) instance.destroy();
  });
}
