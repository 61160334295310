import Remote from './stimulus-remote-rails'
import Flash from 'flash'

export default class CustomRemoteController extends Remote {
  static values = {
    fallbackMethod: String
  }

  error(event) {
    const data = event.detail[0]
    const responseCode = event.detail[1]

    const fallbackMethod = this.hasFallbackMethodValue ? this.fallbackMethodValue : "replace"

    // Display custom error message from server if present
    if (data.error) {
      Flash.error(data.error)
      return
    }

    // Otherwise handle error based on response code
    switch(responseCode) {
      case 'Unprocessable Entity':
        this[fallbackMethod](event)
        break
      default:
        Flash.error('Na serveru došlo k chybě')
    }
  }
}
