import {DataTable} from "simple-datatables";
import {JSTable} from "jstable";

export {CONTACT};

const CONTACT = {};
CONTACT.dataTable = null;
CONTACT.mobileDataTable = null;

// This prevent duplicate load of datatables function when we forward/backward
CONTACT.beforeCacheClear = function() {
    if (CONTACT.dataTable != null) {
        CONTACT.dataTable.destroy();
        CONTACT.dataTable = null
    }
    if (CONTACT.mobileDataTable != null) {
        CONTACT.mobileDataTable.destroy();
        CONTACT.mobileDataTable = null
    }
}

CONTACT.init = function() {
    let contactsTable = document.querySelector('.contacts_datatable');
    if (contactsTable != null) {
        let originalTable = contactsTable.cloneNode(true);
        let nonSortableCells = [...contactsTable.querySelectorAll('th')].map(function(element, index){
            if (element.dataset.sortable == 'false') return index
        }).filter(Number);
        let sortedCol = contactsTable.querySelector('th[data-sorted]');
        let sortedColIdx = [...contactsTable.querySelectorAll('thead th')].findIndex(function(el){ return el == sortedCol});
        let sortedColDirection = 'asc';
        let deferLoading = (contactsTable.dataset.count == undefined) ? null : contactsTable.dataset.count

        if (sortedColIdx == -1) {
            sortedColIdx = 0;
        } else {
            sortedColDirection = sortedCol.dataset.sorted;
        }

        var parse_string_params = function(params) {
            var object = {};
            for (var i = 0; i < params.length-1; i++)
            {
                object[params[i]] = params[i+1];
            }
            return object;
        }

        var padTo2Digits = function(num) {
            return num.toString().padStart(2, '0');
        }

        var formatDate = function(date) {
            return [
                padTo2Digits(date.getDate()),
                padTo2Digits(date.getMonth() + 1),
                date.getFullYear(),
            ].join('.');
        }

        var dateRenderer = function (cell, idx) {
            let data = cell.innerHTML;
            if(data == '')
            {
                return '';
            }
            return formatDate(new Date(parseInt(data)*1000));
        }


        var columns = [{
            select: nonSortableCells,
            sortable: false
        }]

        var theads = contactsTable.querySelectorAll('th');
        for(var i = 0; i < theads.length; i++)
        {
            var thead = theads[i];
            var column = {};
            column.select = [i];
            column.sortable = true;
            if(sortedColIdx == i)
            {
                column.sort = sortedColDirection;
            }
            column.render = thead.dataset.rendermethod ? eval(thead.dataset.rendermethod) : function(cell, idx){ return cell.innerHTML };

            columns.push(column);
        }

        CONTACT.datatable = new JSTable(contactsTable, {
            labels: {
                perPage: 'Zobrazit záznamů: {select}',
                noRows: 'Žadná data',
                placeholder: 'Hledat...',
                info: 'Zobrazuji {start} až {end} z {rows} záznamů',
                loading: 'Načítám data'
            },
            perPage: 20,
            perPageSelect: [5, 10, 20, 40, 60, 80, 100],
            columns: columns,
            searchable: true,
            serverSide: contactsTable.dataset.url != undefined,
            classes: { search: 'dataTable-search', dropdown: 'dataTable-dropdown', input: 'dataTable-input' },
            ajax: contactsTable.dataset.url,
            ajaxParams: contactsTable.dataset.params == undefined ? {} : parse_string_params(contactsTable.dataset.params.split(",")),
            deferLoading: deferLoading
        });
        if(CONTACT.datatable.config.classes.search == 'hidden')
        {
            CONTACT.datatable.search(' ');
        }
        // No idea why wrapper is sometimes undefined
        if (CONTACT.datatable.wrapper) {
            CONTACT.datatable.originalTable = originalTable;
            M.FormSelect.init(CONTACT.datatable.wrapper.querySelector('select'));
        }
        var tooltip_init = function () {
            let tooltipped = CONTACT.datatable.wrapper.querySelectorAll('.tooltipped');
            M.Tooltip.init(tooltipped);
        }
        tooltip_init();

        var tooltip_cleanup = function () {
            let tooltipped = CONTACT.datatable.wrapper.querySelectorAll('.tooltipped');
            tooltipped.forEach(tooltip => {
                const instance = M.Tooltip.getInstance(tooltip);
                if (instance != null) instance.destroy();
            });
        }

        CONTACT.datatable.on('update', tooltip_init);
        CONTACT.datatable.on('fetchData', tooltip_cleanup);
        CONTACT.datatable.on('paginate', function() {
            let url = window.location.pathname + window.location.search
            Turbolinks.controller.replaceHistoryWithLocationAndRestorationIdentifier(location, Turbolinks.uuid())
        });
    }
}
