import {JSTable, JSTableDefaultConfig} from "jstable";
import {Spinner} from "./spinner";
import {getOuterHeight} from "./utils";
import {PAYOUT} from "./payouts";

export {TASK};

const TASK = {};
TASK.dataTable = null;

TASK.toggleTodoUserSelect = function (checkbox) {
  let select = checkbox.closest('form').querySelector('.todo-user-select');
  if (checkbox.checked) {
    select.style.display = 'block';
  } else {
    select.style.display = 'none';
  }
}

TASK.initTodoSwitch = function () {
  let todoSwitches = document.querySelectorAll('.todo-switch');
  todoSwitches.forEach(todoSwitch => {
    TASK.toggleTodoUserSelect(todoSwitch);
    todoSwitch.addEventListener('click', e => {
      console.log(e);
      let target = e.target;
      TASK.toggleTodoUserSelect(target);
    });
  });
}

TASK.addCSORowByEnter = function (e) {
  let key = e.key;
  if (key === "Enter") {
    e.preventDefault();
    TASK.addCSORow();
  }
};

TASK.addCSORow = function () {
  let lastRow = document.querySelector("#cso-rows li:last-child")
  if (lastRow != null && lastRow.querySelector("input").value !== "") {
    let template = document.querySelector("#new-item").innerHTML;
    lastRow.removeEventListener("keypress", TASK.addCSORowByEnter);
    lastRow.querySelector("input").setAttribute("value", lastRow.querySelector("input").value);
    document.querySelector("#cso-rows").innerHTML += template;
    lastRow = document.querySelector("#cso-rows li:last-child");
    lastRow.addEventListener("keypress", TASK.addCSORowByEnter);
    lastRow.querySelector("input").select();
    TASK.addRemoveRowListeners();
  }
}

TASK.addRemoveRowListeners = function () {
  let csoRows = document.querySelectorAll("#cso-rows li");
  csoRows.forEach(csoRow => {
    csoRow.querySelector("input").addEventListener("keydown", e => {
      let key = e.key;
      let target = e.target;
      if (key === "Backspace" && target.value === "" && document.querySelector("#cso-rows").querySelectorAll("li").length > 1) {
        e.preventDefault();
        if (target.parentElement !== document.querySelector("#cso-rows li:last-child")) {
          target.closest("li").remove();
          let newLastRow = document.querySelector("#cso-rows li:last-child input");
          newLastRow.select();
          newLastRow.addEventListener("keypress", TASK.addCSORowByEnter);
        }
      }
    });
    csoRow.querySelector(".btn").addEventListener("click", TASK.addCSORow);
  });
}

TASK.initCSORows = function () {
  let csoRows = document.querySelector("#cso-rows");
  if (csoRows != null) {
    TASK.addRemoveRowListeners();

    let lastCSORow = document.querySelector("#cso-rows li:last-child input");
    if (lastCSORow != null) {
      lastCSORow.addEventListener("keypress", TASK.addCSORowByEnter);
    }
  }
}

TASK.receiveMaterial = function (event) {
  let target = event.target;
  let btn = target.closest('button');
  let url = btn.attributes['data-url'].value;
  let method = btn.attributes['data-method'].value;
  let status = btn.closest('tr').querySelector('.status-human');
  let body = {
    material: {
      status: 'received'
    }
  };
  fetch(url, {
    method: method,
    headers: {
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      'X-CSRF-Token': TASK.CSRF_TOKEN
    },
    body: JSON.stringify(body)
  }).then((response) => response.json()).then((data) => {
    status.innerHTML = 'Přijato';
    btn.style.display = 'none';
  }).catch(error => {
    console.log('Error updating material:', error);
    alert('Aktualizace materiálu se nezdařila!');
  })
}

TASK.initMaterialSelectAll = function () {
  let allSelectAlls = document.querySelectorAll('.select-all-materials');
  allSelectAlls.forEach((selectAll) => {
    selectAll.addEventListener('change', (event) => {
      let target = event.target;
      let checkboxes = target.closest('form')
        .querySelectorAll('input.material-checkbox');
      if (target.checked) {
        checkboxes.forEach((cb) => {
          cb['checked'] = true;
        })
      } else {
        checkboxes.forEach((cb) => {
          cb['checked'] = false;
        })
      }
    })
  })
}

TASK.materialEventSelected = function (elem) {
  let value = elem.value;
  let checkboxes = document.querySelectorAll('#material-edit input.material-checkbox');
  checkboxes.forEach((checkbox) => {
    let available_events = checkbox.attributes['data-states'].value.split(',');
    let div_wrapper = checkbox.closest('.row');
    if (available_events.includes(value)) {
      checkbox.disabled = false;
      div_wrapper.style.display = 'block';
    } else {
      checkbox.disabled = true;
      div_wrapper.style.display = 'none';
    }
  });
}

TASK.initMaterialActions = function () {
  let receiveMaterialBtns = document.querySelectorAll('button.receive-material');
  receiveMaterialBtns.forEach((btn) => {
    btn.addEventListener('click', TASK.receiveMaterial);
  });
  TASK.initMaterialSelectAll();
  let eventSelect = document.querySelector('#material_action');
  if (eventSelect != null) {
    eventSelect.addEventListener('change', (event) => {
      TASK.materialEventSelected(event.target)
    });
    TASK.materialEventSelected(eventSelect);
  }
}

TASK.disableIndexFilters = function() {
  let selects = document.querySelectorAll('#project-filter,#user-filter');
  selects.forEach(select => {
    select.disabled = true;
    M.FormSelect.getInstance(select).destroy();
    M.FormSelect.init(select);
  });
  let filters = document.querySelectorAll('.btn-group > a');
  filters.forEach(filter => {
    filter.classList.add('disabled');
  })
}

TASK.initIndexFilters = function () {
  let projectSelect = document.querySelector('#project-filter');
  if (projectSelect != null) {
    projectSelect.addEventListener('change', (e) => {
      let select = e.target;
      let filter = document.querySelector('#task_filter');
      let userFilter = document.querySelector('#user-filter');
      let userId = '';
      if (userFilter != null) {
        userId = userFilter.value;
      }
      select.closest('form').appendChild(Spinner.createLoadingCircle());
      TASK.disableIndexFilters();
      Turbolinks.visit(`/tasks?filter=${filter.value}&project_id=${select.value}&user_id=${userId}`);
    })
  }
  let userSelect = document.querySelector('#user-filter');
  if (userSelect != null) {
    userSelect.addEventListener('change', (e) => {
      let select = e.target;
      let filter = document.querySelector('#task_filter');
      let projectFilter = document.querySelector('#project-filter');
      let projectId = '';
      if (filter.value === 'default' || filter.value === '') {
        filter.value = 'all';
      }
      if (projectFilter != null) {
        projectId = projectFilter.value;
      }
      select.closest('form').appendChild(Spinner.createLoadingCircle());
      TASK.disableIndexFilters();
      Turbolinks.visit(`/tasks?filter=${filter.value}&project_id=${projectId}&user_id=${select.value}`);
    })
  }
}

TASK.recalculatePreHeight = function () {
  let taskCards = document.querySelector('#task-cards');
  if (taskCards != null) {
    let taskPre = document.querySelector('pre.task-text');
    let taskH3 = taskPre.parentElement.parentElement.parentElement.parentElement.querySelector('h3');
    taskPre.style.height = (getOuterHeight(taskCards) - getOuterHeight(taskH3)) + 'px';
  }
}

TASK.init = function () {
  TASK.CSRF_TOKEN = document.querySelector('meta[name="csrf-token"]').attributes['content'].value;

  function showOptions(action, user) {
    let task_row = document.querySelector("#select_row_" + user.id);
    task_row.style.display = action;
    task_row.querySelector("input").disabled = false;
  }

  let taskForm = document.querySelector('#task-form');
  if (taskForm != null) {
    let taskId = taskForm.dataset.taskId
    if (taskId != null) {
      fetch(`/tasks/${taskId}/get_users/`).then(res => {
        res.json().then(data => {
          data['users'].forEach(user => {
            let option = document.querySelector('#multiple-users-select option[value="' + user.id + '"]')
            option.selected = true;
            showOptions('block', user)
          });
        }).catch(err => {
          alert('Získání aktualních uživatelů se nezdařilo');
          console.log(err);
        })
      }).catch(err => {
        alert('Získání aktualních uživatelů se nezdařilo');
        console.log(err);
      });
    }
  }

  function hideUnhideElement(action, opt) {
    let task_row = document.querySelector("#select_row_" + opt.value);
    task_row.style.display = action;
    task_row.querySelector('input').disabled = action === 'none';
  }

  function getSelectedOptions(sel) {
    let opts = [], opt;
    let len = sel.options.length;
    for (let i = 0; i < len; i++) {
      opt = sel.options[i];

      if (opt.selected) {
        opts.push(opt);
        hideUnhideElement('block', opt)
      } else {
        hideUnhideElement('none', opt)
      }
    }
    return opts;
  }

  let userSelect = document.querySelector('#multiple-users-select');
  if (userSelect != null) {
    M.FormSelect.getInstance(userSelect).destroy();
    let preselections = document.querySelectorAll(".preselections");
    preselections.forEach(sel => {
      let option = document.querySelector(`#multiple-users-select option[value="${sel.id.split("_").slice(-1)[0]}"]`)
      option.selected = true;
      hideUnhideElement('block', option);
    });
    userSelect.addEventListener("change", function () {
      getSelectedOptions(this);
    });
  }
  M.FormSelect.init(userSelect);

  let percSelections = document.querySelectorAll(".task-worker-percentage > input");

  percSelections.forEach(function (sel) {
    sel.addEventListener('input', function () {
      let workerPercentage = parseInt(sel.value);
      if (workerPercentage < 0) {
        sel.value = 0;
      } else if (sel.value > 100) {
        sel.value = 100;
      }
      getTotalPercentage();
    })
  });

  function getTotalPercentage() {
    let taskPercentage = 0;
    for(var i=0; i < percSelections.length; i++) {
      if(parseInt(percSelections[i].value))
      taskPercentage += parseInt(percSelections[i].value);
    }
    console.log (taskPercentage);
    document.getElementById('value').innerHTML = taskPercentage;
    const submit = document.getElementById('submit-workers')
    const infoBadge = document.getElementById('task-percentage')
    const warning = document.getElementById('warning')
    if (taskPercentage == 100) {
      submit.disabled = false;
      infoBadge.classList.add('full');
      warning.style.display = 'none';
    } else {
      submit.disabled = true;
      infoBadge.classList.remove('full');
      warning.style.display = 'block';
    }
    let workerRow = document.querySelectorAll(".task-worker-row");
    for (var i = 0; i < workerRow.length; i++) {
      if (parseInt(percSelections[i].value) !== 0) {
        workerRow[i].classList.add('filled');
      } else {
        workerRow[i].classList.remove('filled');
      }
    }
  }

  let workersForm = document.querySelector('#workers-form');
  if (workersForm != null) {
    getTotalPercentage();

  }



  let taskTable = document.querySelector('.tasks_datatable');
  if (taskTable != null) {
    let originalTable = taskTable.cloneNode(true);
    let nonSortableCells = [...taskTable.querySelectorAll('th')].map(function(element, index){
      if (element.dataset.sortable == 'false') return index
    }).filter(Number);
    let sortedCol = taskTable.querySelector('th[data-sorted]');
    let sortedColIdx = [...taskTable.querySelectorAll('thead th')].findIndex(function(el){ return el == sortedCol});
    let sortedColDirection = 'asc';

    if (sortedColIdx == -1) {
      sortedColIdx = 0;
    } else {
      sortedColDirection = sortedCol.dataset.sorted;
    }

    var parse_string_params = function(params) {
      var object = {};
      for (var i = 0; i < params.length-1; i++)
      {
        object[params[i]] = params[i+1];
      }
      return object;
    }

    var padTo2Digits = function(num) {
      return num.toString().padStart(2, '0');
    }

    var formatDate = function(date) {
      return [
        padTo2Digits(date.getDate()),
        padTo2Digits(date.getMonth() + 1),
        date.getFullYear(),
      ].join('.');
    }

    var dateRenderer = function (cell, idx) {
      let data = cell.innerHTML;
      if(data == '')
      {
        return '';
      }
      return formatDate(new Date(parseInt(data)*1000));
    }


    var columns = [{
      select: nonSortableCells,
      sortable: false
    }]

    var theads = taskTable.querySelectorAll('th');
    for(var i = 0; i < theads.length; i++)
    {
      var thead = theads[i];
      var column = {};
      column.select = [i];
      column.sortable = true;
      if(sortedColIdx == i)
      {
        column.sort = sortedColDirection;
      }
      column.render = thead.dataset.rendermethod ? eval(thead.dataset.rendermethod) : function(cell, idx){ return cell.innerHTML };

      columns.push(column);
    }

    TASK.datatable = new JSTable(taskTable, {
      labels: {
        perPage: 'Zobrazit záznamů: {select}',
        noRows: 'Žadná data',
        placeholder: 'Hledat...',
        info: 'Zobrazuji {start} až {end} z {rows} záznamů',
        loading: 'Načítám data'
      },
      perPage: 20,
      perPageSelect: [5, 10, 20, 40, 60, 80, 100],
      columns: columns,
      searchable: true,
      serverSide: taskTable.dataset.url != undefined,
      classes: { search: 'dataTable-search', dropdown: 'dataTable-dropdown', input: 'dataTable-input' },
      ajax: taskTable.dataset.url,
      ajaxParams: taskTable.dataset.params == undefined ? {} : parse_string_params(taskTable.dataset.params.split(",")),
      deferLoading: null
    });
    if(TASK.datatable.config.classes.search == 'hidden')
    {
      TASK.datatable.search(' ');
    }

    // No idea why wrapper is sometimes undefined
    if (TASK.datatable.wrapper) {
      TASK.datatable.originalTable = originalTable;
      M.FormSelect.init(TASK.datatable.wrapper.querySelector('select'));
    }
    var tooltip_init = function () {
      let tooltipped = TASK.datatable.wrapper.querySelectorAll('.tooltipped');
      M.Tooltip.init(tooltipped);
    }
    tooltip_init();

    var tooltip_cleanup = function () {
      let tooltipped = TASK.datatable.wrapper.querySelectorAll('.tooltipped');
      tooltipped.forEach(tooltip => {
        const instance = M.Tooltip.getInstance(tooltip);
        if (instance != null) instance.destroy();
      });
    }

    TASK.datatable.on('update', tooltip_init);
    TASK.datatable.on('fetchData', tooltip_cleanup);
    TASK.datatable.on('paginate', function() {
      let url = window.location.pathname + window.location.search
      Turbolinks.controller.replaceHistoryWithLocationAndRestorationIdentifier(location, Turbolinks.uuid())
    });
  }

  TASK.initMaterialActions();
  TASK.initIndexFilters();
  TASK.initCSORows();
  TASK.recalculatePreHeight();
  TASK.initTodoSwitch();
}
