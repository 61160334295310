import {initClickableRows} from "./utils";
import {JSTable} from "jstable";
import {PROJECT} from "./projects";
import {TASK} from "./tasks";

export {datatableRegistry};

let datatableRegistry = [];

function initializeDatatables() {
  datatableRegistry = [];
  const tables = document.querySelectorAll('.datatable');

  tables.forEach(table => {
    let originalTable = table.cloneNode(true);
    let nonSortableCells = [...table.querySelectorAll('th')].map(function(element, index){
      if (element.dataset.sortable == 'false') return index
    }).filter(Number);
    let sortedCol = table.querySelector('th[data-sorted]');
    let sortedColIdx = [...table.querySelectorAll('thead th')].findIndex(function(el){ return el == sortedCol});
    let sortedColDirection = 'asc';
    let deferLoading = (table.dataset.count == undefined) ? null : table.dataset.count

    if (sortedColIdx == -1) {
      sortedColIdx = 0;
    } else {
      sortedColDirection = sortedCol.dataset.sorted;
    }

    var parse_string_params = function(params) {
      var object = {};
      for (var i = 0; i < params.length-1; i++)
      {
        object[params[i]] = params[i+1];
      }
      return object;
    }

    var padTo2Digits = function(num) {
      return num.toString().padStart(2, '0');
    }

    var formatDate = function(date) {
      return [
        padTo2Digits(date.getDate()),
        padTo2Digits(date.getMonth() + 1),
        date.getFullYear(),
      ].join('.');
    }

    var dateRenderer = function (cell, idx) {
      let data = cell.innerHTML;
      if(data == '')
      {
        return '';
      }
      return formatDate(new Date(parseInt(data)*1000));
    }

    var currencyRenderer = function (cell, idx) {
      let data = cell.innerHTML;
      if(data[1] == 'n')
      {
        data = data.split('\n')[1];
      }
      var formated = [];
      if(data == '')
      {
        return '';
      }

      var decimal_length = 0;
      for(var i = data.length-1; i >= 0; i--)
      {
        if(data[i] == '.')
        {
          if(decimal_length == 1)
          {
            data += '0';
          }
          break;
        }
        else
        {
          decimal_length++;
        }
      }

      data = data.slice(0, data.length - (decimal_length-2));
      data = data.replace('.', ',');

      for(var i = data.length-1; i >= 0; i--)
      {
        var length_difference = (data.length - i);

        if ((data.length - (i)) % 3 == 0 && i < data.length-3)
        {
          formated[i] = ' ' + data[i];
        }
        else
        {
          formated[i] = data[i];
        }
      }
      var return_string = '';
      for(var i = 0; i < formated.length; i++)
      {
        return_string += formated[i];
      }
      return return_string + " Kč";
    }


    var columns = [{
      select: nonSortableCells,
      sortable: false
    }]

    var theads = table.querySelectorAll('th');
    for(var i = 0; i < theads.length; i++)
    {
      var thead = theads[i];
      var column = {};
      column.select = [i];
      column.sortable = true;
      if(sortedColIdx == i)
      {
        column.sort = sortedColDirection;
      }
      column.render = thead.dataset.rendermethod ? eval(thead.dataset.rendermethod) : function(cell, idx){ return cell.innerHTML };

      columns.push(column);
    }



    const datatable = new JSTable(table, {

      labels: {
        perPage: 'Zobrazit záznamů: {select}',
        noRows: 'Žadná data',
        placeholder: 'Hledat...',
        info: 'Zobrazuji {start} až {end} z {rows} záznamů',
        loading: 'Načítám data'
      },
      perPage: 20,
      perPageSelect: [5, 10, 20, 40, 60, 80, 100],
      columns: columns,
      searchable: true,
      serverSide: table.dataset.url != undefined,
      classes: { search: 'dataTable-search', dropdown: 'dataTable-dropdown', input: 'dataTable-input' },
      ajax: table.dataset.url,
      ajaxParams: table.dataset.params == undefined ? {} : parse_string_params(table.dataset.params.split(",")),
      deferLoading: deferLoading
    });
    if(datatable.config.classes.search == 'hidden')
    {
      datatable.search(' ');
    }
    // No idea why wrapper is sometimes undefined
    if (datatable.wrapper) {
      datatableRegistry.push(datatable);
      datatable.originalTable = originalTable;
      M.FormSelect.init(datatable.wrapper.querySelector('select'));
    }
    var tooltip_init = function () {
      let tooltipped = datatable.wrapper.querySelectorAll('.tooltipped');
      M.Tooltip.init(tooltipped);
    }
    tooltip_init();

    var tooltip_cleanup = function () {
      let tooltipped = datatable.wrapper.querySelectorAll('.tooltipped');
      tooltipped.forEach(tooltip => {
        const instance = M.Tooltip.getInstance(tooltip);
        if (instance != null) instance.destroy();
      });
    }

    datatable.on('update', tooltip_init);
    datatable.on('fetchData', tooltip_cleanup);
    datatable.on('paginate', function() {
      let url = window.location.pathname + window.location.search
      Turbolinks.controller.replaceHistoryWithLocationAndRestorationIdentifier(location, Turbolinks.uuid())
    });
  })
}

export function app_init(){
  let noVisitLinks = document.querySelectorAll('a[href="#!"]');
  noVisitLinks.forEach(link => {
    link.addEventListener('click', e => {
      e.preventDefault();
    })
  });

  initClickableRows();

  initializeDatatables();
}
