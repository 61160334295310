import { Controller } from 'stimulus'
import { setSelectValue } from "../utils";

export default class extends Controller {
  static targets = ['project', 'user', 'clearBtn']
  static values = {
    userId: Number
  }

  connect() {
    this.button_status()
  }

  clear() {
    setSelectValue(this.projectTarget, '')
    setSelectValue(this.userTarget, '')
  }

  set_user() {
    console.log('settingg')
    console.log(this.userIdValue)
    setSelectValue(this.userTarget, this.userIdValue)
  }

  button_status() {
    let invisible = (this.userTarget.value == '') && (this.projectTarget.value == '')
    let display = invisible ? "none" : "initial"
    this.clearBtnTarget.style['display'] = display
  }

}
