import {AutoCompleteUtils} from "./utils";
import autoComplete from '@tarekraafat/autocomplete.js'
export {AUTOCOMPLETE}

const AUTOCOMPLETE = {};

AUTOCOMPLETE.defaultResultsList = element => {
  return {
    render: true,
    container: source => {
      source.classList.add('collection', 'nav-search-results');
      source.removeAttribute('id');
    },
    destination: element,
    element: 'div'
  }
}

AUTOCOMPLETE.defaultResultItem = () => {
  return {
    content: (data, source) => {
      source.innerHTML += data.value.label;
      source.setAttribute('class', 'collection-item');
    },
    element: 'a'
  }
}

AUTOCOMPLETE.bindSapAutocomplete = function (element) {
  const url = element.dataset.autocomplete;
  new autoComplete({
    data: {
      src: async () => {
        const query = element.value;
        const source = await fetch(`${url}?term=${query}`);
        return await source.json();
      },
      key: ['query'],
      cache: false
    },
    placeHolder: 'Part number',
    selector: () => {
      return element;
    },
    threshold: 3,
    maxResults: Infinity,
    searchEngine: 'loose',
    resultsList: {
      container: source => {
        AutoCompleteUtils.createAbsoluteDropdown(source, element)
      },
      destination: () => {
        return document.body;
      },
      className: 'collection autocomplete',
      element: 'div'
    },
    resultItem: {
      content: AutoCompleteUtils.createResultItem,
      element: "a",
      className: 'collection-item'
    },
    onSelection: feedback => {
      feedback.event.preventDefault();
      const item = feedback.selection.value;
      element.value = item.label;
    }
  });
}

AUTOCOMPLETE.bindTaskAutocomplete = function (element) {
  const url = element.dataset.autocomplete;
  new autoComplete({
    data: {
      src: async () => {
        const query = element.value;
        const source = await fetch(`${url}?term=${query}`);
        return await source.json();
      },
      key: ['query'],
      cache: false
    },
    placeHolder: 'Prohledat úkoly..',
    selector: ()=>{
      return element;
    },
    threshold: 3,
    maxResults: Infinity,
    searchEngine: 'loose',
    resultsList: {
      container: source => {
        AutoCompleteUtils.createAbsoluteDropdown(source, element)
      },
      destination: () => {
        return document.body;
      },
      className: 'collection autocomplete',
      element: 'div'
    },
    resultItem: {
      content: (data, source) => {
        source.innerHTML = data.value.label;
        source.setAttribute('class', 'collection-item');
      },
      element: 'a'
    },
    onSelection: feedback => {
      feedback.event.preventDefault();
      const item = feedback.selection.value;
      element.value = item.label;
      element.closest('.row').querySelector('.task-id-input').value = item.id;
    }
  });
}

AUTOCOMPLETE.init = function () {
  const navCompletes = document.querySelectorAll('.nav-autocomplete');

  function getIconByType(type) {
    let icon = '';
    switch (type) {
      case 'contacts':
        icon = 'phone';
        break;
      case 'sites':
        icon = 'business';
        break;
      case 'tasks':
        icon = 'list_alt';
        break;
      case 'comments':
        icon = 'comment';
        break;
      case 'materials':
        icon = 'inbox';
        break;
      case 'companies':
        icon = 'business_center';
        break;
      case 'quotes':
        icon = 'description';
        break;
    }
    return icon;
  }

  function bindAutocomplete(element) {
    new autoComplete({
      data: {
        src: async () => {
          const query = element.value;
          const source = await fetch(`/autocomplete?term=${query}`);
          return await source.json();
        },
        key: ['query'],
        cache: false
      },
      placeHolder: 'Prohledat vše..',
      selector: () => {
        return element;
      },
      threshold: 3,
      maxResults: Infinity,
      searchEngine: 'loose',
      resultsList: {
        render: true,
        container: source => {
          source.classList.add('collection', 'nav-search-results', 'z-depth-3');
          source.removeAttribute('id');
        },
        destination: () => {
          return element;
        },
        element: 'div'
      },
      resultItem: {
        content: (data, source) => {
          const icon = document.createElement('i');
          icon.classList.add('material-icons', 'left', 'nav-icon');
          icon.innerHTML = getIconByType(data.value.type);
          source.innerHTML = data.value.label;
          source.appendChild(icon);
          source.setAttribute('class', 'collection-item');
        },
        element: 'a'
      },
      onSelection: feedback => {
        const item = feedback.selection.value;
        Turbolinks.visit(item.link)
      }
    });

    element.type = 'search';
  }

  navCompletes.forEach(bindAutocomplete);
  const taskAutocompletes = document.querySelectorAll('.autocomplete_tasks');
  taskAutocompletes.forEach(AUTOCOMPLETE.bindTaskAutocomplete)

  const sapAutocompletes = document.querySelectorAll('.autocomplete_parts');
  sapAutocompletes.forEach(AUTOCOMPLETE.bindSapAutocomplete)

}
